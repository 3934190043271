<template>
  <div class="yunvideo">
    <!-- nav -->
    <div class="wrap">
      <div class="nav w">
        <ul>
          <li>
            <a @click="topClick(2)"
               :class="[isimg==2?'active li':'li']"
               href="javascript:;">展商详情</a>
          </li>
          <!-- <li>
            <a @click="topClick(1)" :class="[isimg==1?'active li':'li']" href="javascript:;">展商商品</a>
          </li> -->
        </ul>
      </div>
    </div>
    <!-- 图片 -->
    <div class="list w mb100 clearfix"
         v-if="isimg==1">
      <div class="item"
           v-for="(item,index) in dataList"
           :key="index">
        <div class="item-left">
          <img :src="item.image"
               alt />
          <div class="box1 boxBg"
               v-if="item.pavilion_name!==''">
            <p>{{item.pavilion_name}}</p>
          </div>
        </div>
        <div class="item-right">
          <h5>{{item.product_name}}</h5>
          <p>展区：{{item.area_name}}</p>
          <p>展馆：{{item.name}}</p>
          <p>展位：{{item.booth}}</p>
          <p>品类：{{item.title}}</p>
        </div>
      </div>
    </div>
    <!-- 图片 -->
    <div class="list w clearfix"
         v-if="isimg==2">
      <div class="item">
        <div class="item-left">
          <img :src="datasContent.logo"
               alt />
          <div class="box1">
            <p>{{datasContent.pavilion_name}}</p>
          </div>
        </div>
        <div class="item-right">
          <h5>{{datasContent.company}}</h5>
          <p>展区：{{datasContent.area_name}}</p>
          <p>展馆：{{datasContent.name}}</p>
          <p>展位：{{datasContent.exhibition_num}}</p>
          <p>品类：{{datasContent.title}}</p>
        </div>
      </div>
    </div>
    <!-- 图文 -->
    <div class="image mb100 w clearfix contents"
         v-if="isimg==2">
      <h5 class="headContent">展商介绍</h5>
      <div v-html="datasContent.content"></div>
    </div>

    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
import { ProductList, ProductListContent } from "@/api/index";

export default {
  name: "Streamf",
  components: {
  },
  data () {
    return {
      isimg: "2",
      datasContent: [],
      dataList: []
    };
  },

  mounted () {

  },
  created () {
    this.initListProduct();
    this.listContent();
  },
  watch: {
    $route: {
      handler () {
        this.initListProduct();
        this.listContent();
      },
      deep: true
    }
  },
  methods: {
    listContent () {
      console.log(111)
      let ids = {
        id: Number(this.$route.query.id)
      }
      ProductListContent(ids).then(res => {
        this.datasContent = res.data
      })
    },
    initListProduct () {
      let datas = {
        page: 1,
        limit: 30,
        id: Number(this.$route.query.id)
      }
      ProductList(datas).then(res => {
        this.dataList = res.data.data
      })
    },
    topClick (obj) {
      this.isimg = obj;
    }
  }
};
</script>

<style scoped  lang="scss">
.contents {
  line-height: 30px;
}
.headContent {
  padding: 20px 0 30px 0;
}
.wrap {
  background: #f5f5f5;
  height: 100px;

  .nav {
    ul {
      li {
        float: left;
        height: 100px;
        line-height: 100px;
        .li {
          position: relative;
          display: inline-block;
          text-decoration: none;
          font-size: 26px;
          color: #4c4c4c;
          height: 100px;
          line-height: 100px;
          margin-right: 80px;
        }

        .active {
          position: relative;
          text-decoration: none;
          color: #333333;
          font-weight: 500;
          font-size: 26px;
          color: #0e9b32;

          &::after {
            position: absolute;
            // bottom: -33px;
            left: -2px;
            content: "";
            width: 100%;
            height: 3px;
            background: #0e9b32;
            display: block;
          }
        }
      }
    }
  }
}

.image {
  h5 {
    font-weight: 600;
    color: #3a3a3a;
    line-height: 28px;
    font-size: 20px;
  }

  p {
    color: #333333;
    line-height: 24px;
    margin-top: 17px;
  }
}

.list {
  //   height: 600px;
  //   background: orangered;
  margin-top: 60px;
  // margin-bottom: 70px;

  .item {
    width: 600px;
    height: 190px;
    // background: lightcoral;
    float: left;
    margin-bottom: 43px;

    .item-left {
      width: 285px;
      height: 190px;
      float: left;
      position: relative;

      .box1 {
        position: absolute;
        bottom: 0;
        width: 285px;
        height: 30px;
        background: #54c074;
        opacity: 0.85;

        p {
          display: inline-block;
          color: #fff;
          font-size: 14px;
          line-height: 30px;
          margin-left: 13px;
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item-right {
      box-sizing: border-box;
      padding-left: 32px;
      width: 315px;
      height: 190px;
      // background: yellow;
      float: left;

      h5 {
        font-size: 20px;
        font-weight: 600;
        color: #3a3a3a;
        margin-top: 11px;
        margin-bottom: 9px;
      }
      p {
        color: #666666;
        margin-top: 24px;
      }
    }
  }
}

.swiper-container {
  height: 500px;
  width: 100%;

  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
      // background-color: #42b983;
      text-align: center;
      line-height: 500px;
      height: 100%;
      width: 100%;
      background: url(../assets/images/banner4.jpg) no-repeat center top;
      background-size: 1920px 500px;
    }
  }
}
.activeLi {
  background: #0ba539;
  color: white;
}
.mb100 {
  margin-bottom: 100px;
}
.boxBg {
  background: rgba(0, 0, 0, 0.6) !important;
}
</style>